import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { AiOutlinePlus } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";

export default function ChangeImage({ onFileChange, photo }) {
  const [selectedImage, setSelectedImage] = useState(photo);
  const [file, setFile] = useState();

  const handleImageUpload = ({ base64 }) => {
    setFile(base64);
    setSelectedImage(base64);
    onFileChange(base64);
  };

  const handleRemoveImage = () => {
    // Clear the selected image
    setSelectedImage(null);
    // Clear the file input value to allow selecting the same file again
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  return (
    <>
      <div
        className={`h-full p-4 border border-black border-dashed rounded-lg ${
          selectedImage ? "flex flex-col items-end" : "flex justify-center"
        }`}
      >
        {selectedImage ? (
          ""
        ) : (
          <>
            <div className='z-10'>
              <FileBase64 className='hidden' onDone={handleImageUpload} />
            </div>
            <div className='absolute mx-auto my-0 z-[1]'>
              <AiOutlinePlus size={24} />
            </div>
          </>
        )}

        {selectedImage && (
          <>
            <button
              className='btn btn-ghost normal-case'
              onClick={handleRemoveImage}
            >
              <GrFormClose size={20} />
            </button>
            <img
              src={selectedImage}
              alt='Selected'
              className='object-cover w-full h-52 rounded-lg'
            />
          </>
        )}
      </div>
      <h1 className='font-semibold'>Upload your event art</h1>
      {/* <button className='btn btn-primary normal-case'>Save</button> */}
    </>
  );
}
