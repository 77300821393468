import React from "react";

export default function callToAction({ title, content, cta }) {
  return (
    <section className='mx-auto my-0 max-w-7xl w-full px-4 md:px-0'>
      <div className='bg-black rounded-sm text-white py-8 md:py-16 px-4 flex flex-col gap-8 items-center text-center'>
        <div className='flex flex-col gap-4 max-w-xl'>
          <h1 className='md:text-2xl text-xl font-black'>{title}</h1>
          <span>{content}</span>
        </div>
        <button
          className='bg-white btn font-medium btn-wide text-foreground'
          size='lg'
        >
          {cta}
        </button>
      </div>
    </section>
  );
}
