import React, { useEffect, useState } from "react";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";
import * as yup from "yup";
import auth from "../../api/auth";
import Loader from "../loader";

const validationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("Please enter a valid email address"),
  phoneNumber: yup.string(),
});

function Profile() {
  const [account, setAccount] = useState(null);

  const user = window.localStorage.getItem("user");

  useEffect(() => {
    const getAccountDetails = async () => {
      const customer = JSON.parse(window.localStorage.getItem("customer"));
      if (user) {
        const response = await auth.getAccount(user);
        if (!response.ok) return console.log(response);
        setAccount(response.data);
      }
      setAccount(customer.user);
    };
    getAccountDetails();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const handleOnSubmit = async ({ name, email, phoneNumber }) => {
    const response = await auth.editAccount({ name, email, phoneNumber });
    if (!response.ok) return console.log(response);
    window.alert("Profile details updated successfully");
  };

  if (!account) {
    return <Loader />;
  }

  return (
    <AppForm
      initialValues={{
        name: account.name,
        email: account.email,
        phoneNumber: account.phoneNumber,
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {user && <AppFormField name='name' title='Full Name' type='name' />}
      <AppFormField name='email' title='Email Address' type='email' />
      {user && <AppFormField name='phoneNumber' title='Phone Number' />}
      <button className='btn btn-primary normal-case' type='submit'>
        Change
      </button>
    </AppForm>
  );
}

export default Profile;
