import client from './client';
const endpoint = 'coordinator/';

const createCoordinator = async (body) => {
  const response = await client.post(endpoint, body);
  return response;
};

const getCoordinators = async () => {
  const response = await client.get(`${endpoint}`);
  return response;
};

const getCoordinator = async (id) => {
  const response = await client.get(`${endpoint}specific/${id}`);
  return response;
};

const updateCoordinator = async (coordinatorId, body) => {
  const response = await client.put(`${endpoint}${coordinatorId}`, body);
  return response;
};

const deleteCoordinator = async (coordinatorId) => {
  const response = await client.delete(`${endpoint}${coordinatorId}`);
  return response;
};

const authenticateCoordinator = async (id, body) => {
  const response = await client.post(`${endpoint}authenticate/${id}`, body);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCoordinator,
  getCoordinator,
  getCoordinators,
  updateCoordinator,
  deleteCoordinator,
  authenticateCoordinator
};
