export default function CoordinatorDetailSkeleton() {
  return (
    <>
      <div className='max-h-screen space-y-6 animate-pulse px-4 pt-20 ease-in-out duration-700 '>
        <div className='flex items-center justify-between'>
          <div class='bg-slate-200 rounded h-4 w-36'></div>
          <div class='bg-slate-200 rounded h-4 w-16'></div>
        </div>

        <div class='bg-slate-200 rounded-lg h-28 w-full'></div>

        <div class='bg-slate-200 rounded-lg h-10 w-full'></div>

        <div class='bg-slate-200 rounded-lg h-80 w-full'></div>
      </div>
    </>
  );
}
