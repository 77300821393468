export default function BuyTicketSkeleton() {
  return (
    <>
      <div className='max-h-screen space-y-6 animate-pulse p-4 ease-in-out duration-700'>
        <div className='flex justify-end'>
          <div class='bg-slate-200  w-10 h-10 rounded-full shrink-0'></div>
        </div>
        <div class='bg-slate-200 rounded-lg h-96 w-full'></div>
        <div className='space-y-4'>
          <div class='bg-slate-200 rounded h-4 w-44'></div>
          <div class='bg-slate-200 rounded h-2 w-28'></div>
          <div class='bg-slate-200 rounded h-2 w-20'></div>
        </div>
      </div>
      <div className='p-4 bg-white border-t rounded-t-xl border-gray-300  w-full block fixed bottom-0 left-0 md:hidden'>
        <div class='bg-slate-200 rounded h-10 w-full'></div>
      </div>
    </>
  );
}
