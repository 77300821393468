import { RiEyeCloseFill, RiEyeCloseLine } from "@remixicon/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import "../../index.css";

export default function AppFormField({
  name,
  title,
  type,
  placeholder,
  autoComplete,
}) {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = showPassword ? "text" : type;
  return (
    <div className='text-left text-black w-full'>
      <label className='label'>
        <span className='label-text'>{title}</span>
      </label>
      <div className='w-full px-3 rounded-sm hover:border-neutral border border-neutral-content focus:border-neutral flex items-center justify-between'>
        <input
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={handleChange}
          id={name}
          name={name}
          type={inputType}
          value={values[name]}
          className='w-full border-0 bg-transparent py-3 focus:outline-none'
          autoComplete={autoComplete}
        />
        {type === "password" && (
          <button
            type='button'
            className='btn btn-sm btn-square btn-ghost'
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <RiEyeCloseFill size={20} />
            ) : (
              <RiEyeCloseLine size={20} />
            )}
          </button>
        )}
      </div>
      {touched[name] && errors[name] ? (
        <div className='mt-2 text-error'>{errors[name]}</div>
      ) : null}
    </div>
  );
}
