import { GrFormClose } from "react-icons/gr";
import AppForm from "./forms/AppForm";
import AppFormField from "./forms/AppFormFied";
import { RiCloseFill } from "@remixicon/react";

export default function Modal({ state, toggle, title, children, showTitle }) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {state && (
        <div
          onClick={() => toggle()}
          className='w-full h-screen pt-8 z-[40] px-4 bg-[#0000007e] fixed inset-0'
        >
          <div
            onClick={stopPropagation}
            className='flex flex-col gap-4 max-w-md mx-auto z-[60] h-fit max-h-full overflow-y-scroll bg-base-100 space-y-6 p-4 md:p-6 rounded-sm'
          >
            <div className='flex sticky bg-base-100 items-center justify-end'>
              <button
                className='btn btn-sm btn-neutral text-white btn-circle'
                onClick={() => toggle()}
              >
                <RiCloseFill size={25} />
              </button>
            </div>

            {showTitle ? (
              <h1 className='font-bold text-xl md:text-2xl uppercase'>
                {title}
              </h1>
            ) : (
              ""
            )}

            {children}
          </div>
        </div>
      )}
    </>
  );
}
