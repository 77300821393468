import { AppForm, AppFormField, Footer, Navbar1 } from "../components";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import auth from "../api/auth";
import Loader from "../components/loader";
import { FcGoogle } from "react-icons/fc";
import AuthHeader from "../components/forms/authHeader";

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6, "Your Password is too weak!")
    .max(50, "Too Long!")
    .required("This field is required"),
  confirmNewPassword: yup
    .string()
    .min(6, "Your Password is too weak!")
    .max(50, "Too Long!")
    .required("This field is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export default function PasswordRest() {
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("id")
  const token = searchParams.get("token")
  // 
  const handleResetPassword = async ({ newPassword, confirmNewPassword }) => {
    console.log("reset button clicked", newPassword, id, token);
    // const response = await auth.resetPassword(id, token, newPassword);
    // console.log("reset function called", response);
    alert("Congrats! You have successfully reset your password");
  };

  return (
    //
    <>
      <Navbar1 />
      <div className="h-screen max-w-7xl mx-auto flex p-4 justify-center">
        <AppForm
          initialValues={{
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleResetPassword}
        >
          <h1 className="font-bold uppercase text-xl md:text-2xl text-left">
            CREATE NEW PASSWORD
          </h1>
          <AppFormField name="newPassword" title="New Password" type="password" />
          <AppFormField
            name="confirmNewPassword"
            title="Retype New Password"
            type="password"
          />
          <button
            className="btn btn-primary font-medium text-white"
            type="submit"
          >
            Change
          </button>
        </AppForm>
      </div>
      <Footer />
    </>
  );
}
