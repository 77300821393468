import React from "react";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const Socialdetail = ({ data, onChange, onNext, onBack }) => {
  const handleOnSubmit = ({ website, instagram, twitter }) => {
    console.log("Values submitted:", { website, instagram, twitter });

    // Update the parent component's data
    onChange("website", website);
    onChange("instagram", instagram);
    onChange("twitter", twitter);

    // Move to the next step
    onNext();
  };

  return (
    <AppForm
      initialValues={{
        website: data.website,
        twitter: data.twitter,
        instagram: data.instagram,
      }}
      onSubmit={handleOnSubmit}
    >
      <AppFormField
        type='text'
        name='website'
        title='Website Link'
        placeholder='e.g www.oloshofest.com'
      />
      <AppFormField
        type='text'
        name='instagram'
        title='Instagram Handle'
        placeholder='e.g Olosho_fest'
      />
      <AppFormField
        type='text'
        name='twitter'
        title='Twitter Handle'
        placeholder='e.g Olosho_fest'
      />
      <button
        onClick={onBack}
        className='btn btn-secondary normal-case'
        type='button'
      >
        Back
      </button>
      <button className='btn btn-primary normal-case' type='submit'>
        Next
      </button>
    </AppForm>
  );
};

export default Socialdetail;
