import React, { useEffect, useState } from "react";
import * as yup from "yup";
import eventApi from "../../api/events";
import tickets from "../../api/tickets";
import Loader from "../loader";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const validationSchema = yup.object().shape({
  ticketName: yup.string().required("Ticket Name field is required"),
  description: yup.string().required("Description field is required"),
  price: yup.number("Please Input a number"),
  ticketNumber: yup.number("Please Input a number"),
});

function Ticketform() {
  const [toggle, setToggle] = useState(2);
  const [limitedQuantity, setLimitedQuantity] = useState(true);
  const [free, setFree] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getEventDetails = async () => {
      const queryString = window.location.href.split("?")[1];
      const response = await eventApi.getEvent(queryString);
      if (!response.ok) return console.log(response);
      setEventDetails(response.data);
    };
    getEventDetails();
  }, []);

  const toggleTab = (index) => {
    setToggle(index);
  };

  const handleOnSubmit = async ({
    ticketName,
    description,
    price,
    ticketNumber,
  }) => {
    setLoading(true);
    const eventName = eventDetails.eventName;
    const response = await tickets.createTicket(
      ticketName,
      description,
      limitedQuantity,
      ticketNumber,
      price,
      eventName
    );
    // eslint-disable-next-line
    if (!response.ok) return console.log(response), setLoading(false);
    setLoading(false);
    window.location.replace("/editevent?" + eventDetails._id);
  };

  const handleOnChange = (e) => {
    if (e.target.value === "Limited Quantity") {
      return setLimitedQuantity(true);
    } else if (e.target.value === "Unlimited Quantity") {
      return setLimitedQuantity(false);
    }
  };

  const handleOnChangeQuantity = (e) => {
    if (e.target.value === "Paid") {
      return setFree(true);
    } else if (e.target.value === "Free") {
      return setFree(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!eventDetails) {
    return <Loader />;
  }

  return (
    <div className='w-full'>
      <AppForm
        initialValues={{
          ticketName: "",
          description: "",
          limitedQuantity: false,
          price: 0,
          ticketNumber: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <div className='flex items-center justify-between gap-4 md:gap-10'>
          <div className='text-left w-full'>
            <label className='label'>
              <span className='label-text'>Quantity</span>
            </label>
            <div className='w-full px-3 rounded-lg border border-black flex items-center justify-between'>
              <select
                onChange={handleOnChangeQuantity}
                name='Free'
                id='ticket'
                className='w-full py-4 border-0 bg-none focus:outline-none'
              >
                <option value='Free'>Free</option>
                <option value='Paid'>Paid</option>
              </select>
            </div>
          </div>
        </div>

        {free ? <AppFormField name='price' title='Ticket Price' /> : null}

        <div className='tabcontent active flex flex-col gap-5 md:gap-10'>
          <AppFormField name='ticketName' title='Ticket Name' />

          <div className='flex items-center justify-between gap-4 md:gap-10'>
            <div className='text-left w-full'>
              <label className='label'>
                <span className='label-text'>Quantity</span>
              </label>
              <div className='w-full px-3 rounded-lg border border-black flex items-center justify-between'>
                <select
                  onChange={handleOnChange}
                  name='limitedQuantity'
                  id='cars'
                  className='w-full py-4 border-0 bg-none focus:outline-none'
                >
                  <option value='Limited Quantity'>Limited Quantity</option>
                  <option value='Unlimited Quantity'>Unlimited Quantity</option>
                </select>
              </div>
            </div>

            {limitedQuantity ? (
              <AppFormField
                marginBot='0'
                margin='0'
                type='number'
                name='ticketNumber'
                title='Number of Tickets'
              />
            ) : null}
          </div>

          <AppFormField name='description' title='Ticket Description' />
        </div>

        <button className='btn btn-primary normal-case' type='submit'>
          Save
        </button>
      </AppForm>
    </div>
  );
}

export default Ticketform;
