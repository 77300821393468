import React from "react";
import moment from 'moment';

export default function payoutItem(props) {
  const date = moment(props.props.requestDate, 'YYYY-MM-DD, h:mm:ss a').format('ll');

  return (
    <>
      <div className="grid grid-cols-3 items-center justify-between py-4 border-b border-gray-200">
        <p className="font-semibold">₦{props.props.amountDue}</p>
        <p className="text-center">{date}</p>
        <div className="flex justify-end">
          <span className={props.props.fufilled ? "badge badge-success" + ' ✔' : "badge badge-ghost"}>{props.props.fufilled ? "Paid" + ' ✔' : "Pending"}</span>
        </div>
      </div>
    </>
  );
}
