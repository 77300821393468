import React, { useEffect, useState } from "react";
import * as yup from "yup";
import auth from "../../api/auth";
import Loader from "../loader";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const validationSchema = yup.object().shape({
  accountName: yup.string(),
  bankName: yup.string(),
  accountNumber: yup.string(),
});

function Payment() {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const getAccountDetails = async () => {
      const id = window.localStorage.getItem("user");
      const response = await auth.getAccount(id);
      if (!response.ok) return console.log(response);
      setAccount(response.data.bankDetails);
    };
    getAccountDetails();
  }, []);

  const handleOnSubmit = async ({ accountName, bankName, accountNumber }) => {
    const response = await auth.editAccount({
      bankDetails: { accountName, bankName, accountNumber },
    });
    if (!response.ok) return console.log(response);
    window.alert("Payment details updated successfully");
  };

  if (!account) {
    return <Loader />;
  }
  return (
    <AppForm
      initialValues={{
        accountName: account.accountName,
        bankName: account.bankName,
        accountNumber: account.accountNumber,
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      <AppFormField name='accountName' title='Account Name' />
      <AppFormField name='bankName' title='Bank Name' />
      <AppFormField name='accountNumber' title='Account Number' />
      <button className='btn btn-primary normal-case' type='submit'>
        Change
      </button>
    </AppForm>
  );
}

export default Payment;
