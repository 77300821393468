import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { BsFillImageFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import events from "../../api/events";
import Loader from "../loader";

function Uploadform() {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // function handleChange (event) {
  //   setFile(event.target.files[0]);
  // }

  const navigate = useNavigate();

  const handleImageUpload = ({ base64 }) => {
    setFile(base64);
    setSelectedImage(base64);
  };

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();

    const event1 = JSON.parse(window.localStorage.getItem("event1"));
    const event2 = JSON.parse(window.localStorage.getItem("event2"));
    const event3 = JSON.parse(window.localStorage.getItem("event3"));
    const socialMedia = {
      website: event3.website,
      instagram: event3.instagram,
      facebook: event3.facebook,
      twitter: event3.twitter,
    };
    const dateAndTime = {
      startDate: event2.startDate,
      startTime: event2.startTime,
      endDate: event2.endDate,
      endTime: event2.endTime,
    };
    const response = await events.createEvent(
      event1.eventName,
      event1.description,
      file,
      event1.location,
      socialMedia,
      dateAndTime
    );
    // eslint-disable-next-line
    if (!response.ok) return console.log(response), setLoading(false);
    setLoading(false);
    navigate("/createevent3?" + response.data._id);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className='w-full flex gap-10 flex-col p-6 border-gray-200 border-dashed border rounded-lg'
    >
      <label
        htmlFor='input-file-button'
        className='items-center flex flex-col justify-center text-center gap-6'
      >
        <BsFillImageFill size={30} color='#00A8E8' />
        Upload a PNG file: looks best with portrait images
        <FileBase64
          type='file'
          multiple={false}
          onDone={handleImageUpload}
        ></FileBase64>
        {selectedImage && <img src={selectedImage} alt='Selected' />}
      </label>

      <button className='btn btn-primary normal-case' type='submit'>
        Next
      </button>
    </form>
  );
}

export default Uploadform;
