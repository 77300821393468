import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

function Coordinatornames(props) {
  return (
    <Link
      to={props.link}
      className='flex w-full items-center justify-between py-4 border-b border-gray-300'
    >
      {props.eventname}
      <BiChevronRight />
    </Link>
  );
}

export default Coordinatornames;
