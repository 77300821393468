import React from "react";
import { Link } from "react-router-dom";

function eventsList(props) {
  return (
    <Link to={props.link}>
      {/* somethhhhh */}

      <div
        className="flex flex-col p-4 h-60 w-full col-span-1 md:w-96 md:h-80 items-start justify-between text-white rounded-lg bg-center bg-cover"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url(${props.eventimg})`,
        }}
      >
        <p className="text-white">
          <b>{props.eventname}</b>
        </p>
        <div className="flex items-center justify-between w-full">
          <div className="text-left">
            <div>
              <b>Date</b>
            </div>
            <div>
              <b>{props.date}</b>
            </div>
          </div>

          <div className="text-right">
            <div>
              <b>Location</b>
            </div>
            <div>
              <b>{props.location}</b>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default eventsList;
