import { BiArrowBack } from "react-icons/bi";
import { PaystackButton } from "react-paystack";

export default function CheckoutSummary({
  ticketCart = {},
  finalPrice,
  paspotCut,
  componentProps,
  handleBackToTickets,
}) {
  const feePrice = finalPrice * 0.07 + 100;
  return (
    <div className='flex flex-col gap-4'>
      <button
        onClick={handleBackToTickets}
        className='flex items-center gap-2 uppercase text-accent font-medium'
      >
        <BiArrowBack size={20} /> Back
      </button>
      <h2 className='font-bold text-2xl uppercase text-left'>
        Checkout Summary
      </h2>
      <ul className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          {Object.entries(ticketCart).map(([ticketName, ticketDetails]) => (
            <li key={ticketName} className='flex items-center justify-between'>
              <span className='font-medium'>
                {ticketDetails.number} {ticketName}
              </span>
              <span>₦{ticketDetails.price}</span>
            </li>
          ))}
          <div className='divider m-0'></div>
          <div className='flex flex-col gap-2 text-neutral-content text-sm'>
            <li className='flex items-center justify-between'>
              <span>Fees</span> <span>₦{feePrice.toFixed(2)}</span>
            </li>
            <li className='flex items-center justify-between'>
              <span>Subtotal</span> <span>₦{finalPrice + feePrice}</span>
            </li>
          </div>
        </div>
      </ul>
      <li className='text-lg font-semibold flex items-center justify-between'>
        <span>TOTAL</span>{" "}
        <span>₦{parseFloat(finalPrice + feePrice).toLocaleString()}</span>
      </li>
      <PaystackButton
        className='btn btn-primary btn-block text-white font-medium'
        {...componentProps}
      />
    </div>
  );
}
