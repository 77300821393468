import React from "react";

const webHeader = (props) => {
  return (
    <div className='bg-alt bg-fixed bg-center bg-no-repeat h-[70vh] md:h-full md:py-32 text-white'>
      <div className='max-w-4xl mx-auto h-full flex flex-col justify-center items-center px-4 gap-8'>
        <h1 className='font-bold uppercase text-4xl md:text-6xl text-center'>
          {props.title}
        </h1>
      </div>
    </div>
  );
};

export default webHeader;
