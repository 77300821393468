import React, { useState } from "react";
import FileBase64 from "react-file-base64";
import { AiOutlinePlus } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const validationSchema = yup.object().shape({
  startDateTime: yup.date().required("Start Date and Time field is required"),
  endDateTime: yup.date().required("End Date and Time field is required"),
});

function Timedetail({ data, onChange, onNext, onBack }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState();
  const navigate = useNavigate();

  const handleImageUpload = ({ base64 }) => {
    setFile(base64);
    setSelectedImage(base64);
  };

  const handleRemoveImage = () => {
    // Clear the selected image
    setSelectedImage(null);
    // Clear the file input value to allow selecting the same file again
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleOnSubmit = ({ startDateTime, endDateTime }) => {
    const startDate = new Date(startDateTime).toISOString().split("T")[0];
    const startTime = new Date(startDateTime).toTimeString().split(" ")[0];
    const endDate = new Date(endDateTime).toISOString().split("T")[0];
    const endTime = new Date(endDateTime).toTimeString().split(" ")[0];

    onChange("startDateTime", startDateTime);
    onChange("endDateTime", endDateTime);
    // window.localStorage.setItem(
    //   "event2",
    //   JSON.stringify({ startDate, startTime, endDate, endTime })
    // );
    // navigate("/socials");

    onNext();
  };

  return (
    <div className='space-y-8'>
      <div className='text-center space-y-2'>
        <div
          className={`h-full p-4 border border-black border-dashed rounded-lg ${
            selectedImage ? "flex flex-col items-end" : "flex justify-center"
          }`}
        >
          {selectedImage ? (
            ""
          ) : (
            <>
              <div className='z-10'>
                <FileBase64 className='hidden' onDone={handleImageUpload} />
              </div>
              <div className='absolute mx-auto my-0 z-[1]'>
                <AiOutlinePlus size={24} />
              </div>
            </>
          )}

          {selectedImage && (
            <>
              <button
                className='btn btn-ghost normal-case'
                onClick={handleRemoveImage}
              >
                <GrFormClose size={20} />
              </button>
              <img
                src={selectedImage}
                alt='Selected'
                className='object-cover w-full h-52 rounded-lg'
              />
            </>
          )}
        </div>
        <h1 className='font-semibold'>Upload your event art</h1>
      </div>
      <AppForm
        initialValues={{
          startDateTime: data.startDateTime,
          endDateTime: data.endDateTime,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <AppFormField
          name='startDateTime'
          type='datetime-local'
          title='When does your event start'
        />
        <AppFormField
          name='endDateTime'
          type='datetime-local'
          title='When does it end'
        />
        {/* <AppFormField
          name='playlist'
          type='text'
          title='Spotify / Apple Music Playlist'
        /> */}
        <button
          onClick={onBack}
          className='btn btn-primary normal-case'
          type='submit'
        >
          Back
        </button>

        <button className='btn btn-primary normal-case' type='submit'>
          Next
        </button>
      </AppForm>
    </div>
  );
}

export default Timedetail;
