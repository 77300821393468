import { useState } from "react";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSend = () => {
    const encodedName = encodeURIComponent(name);
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/+2347081515331?text=My%20name%20is%20${encodedName}%20and%20I'm%20contacting%20about%20-%20${encodedMessage}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 items-start gap-8 px-4 md:px-8 bg-black text-white py-16 rounded-none md:rounded-md'>
      <div className='space-y-4 max-w-xl'>
        <p className='font-medium text-xl'>Talk to us</p>
        <h1 className='font-semibold text-3xl'>
          Find out more about working with Paspot
        </h1>
      </div>
      <form className='form-control space-y-4 text-black'>
        <input
          type='text'
          value={name}
          placeholder='Name'
          className='w-full p-4 border rounded-md border-black focus:outline-none'
          onChange={(e) => setName(e.target.value)}
        />

        <textarea
          value={message}
          placeholder='I want to know about...'
          className='w-full p-4 border border-black focus:outline-none rounded-md'
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          className='btn btn-primary btn-block md:btn-wide'
          onClick={handleSend}
        >
          Send
        </button>
      </form>
    </div>
  );
}
