import { Link } from "react-router-dom";

export default function Footer({ links = true }) {
  const menuData = [
    {
      title: "SOCIALS",
      links: [
        {
          text: "Instagram",
          href: "https://instagram.com/paspot.ng",
        },
        {
          text: "Twitter",
          href: "https://twitter.com/Paspot_ng",
        },
        {
          text: "Tiktok",
          href: "https://www.tiktok.com/@cruizespot",
        },
      ],
    },
    {
      title: "RESOURCES",
      links: [
        { text: "About", href: "/about" },
        { text: "Press", href: "/press" },
        { text: "Help Desk", href: "/help" },
      ],
    },
    {
      title: "PARTNERSHIP",
      links: [
        { text: "Event Planners", href: "/partner/organisers" },
        { text: "Venues", href: "/partner/venues" },
        { text: "Urgent 2k", href: "/partner/urgent2k" },
      ],
    },
  ];
  return (
    <footer className='pt-16 md:pt-24 w-full flex flex-col items-center justify-center md:px-0 bg-black text-white dark:border-t border-foreground-50'>
      <div className='flex flex-wrap justify-between items-start w-full px-4 md:max-w-6xl md:px-0 border-b border-foreground-50 pb-8'>
        <h1 className='font-black mb-12 text-2xl md:text-4xl uppercase'>
          More than just ticketing
        </h1>

        {links && (
          <div className='flex flex-wrap items-start justify-between gap-10'>
            {menuData.map((section, index) => (
              <div key={index}>
                <span
                  style={{ fontFamily: "var(--font-neueMachina)" }}
                  className='font-bold'
                >
                  {section.title}
                </span>
                <ul className='flex flex-col gap-4 mt-6'>
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <Link href={link.href}>{link.text}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className='py-20 pb-10 px-8 md:px-0 w-full md:max-w-6xl flex flex-wrap items-center justify-between'>
        <span>Copyright © 2024. Paspot Global Ventures.</span>
        <ul className='flex w-full md:w-auto pt-10 md:pt-0 items-center gap-10 md:justify-center justify-between'>
          <li>
            <Link href='/terms&conditions' className='hover:text-primary'>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link href='/terms&conditions' className='hover:text-primary'>
              Terms & Conditions
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
