import client from "./client";

const endpoint = "account/";

const signUp = async (name, email, phoneNumber, password, bankDetails) => {
  const response = await client.post(
    endpoint,
    {
      name,
      phoneNumber,
      email,
      password,
      bankDetails,
    },
    { headers: { Authorization: "" } }
  );
  return response;
};

const login = async (email, password) => {
  const response = await client.post(
    endpoint + "login/",
    { email, password },
    { headers: { "Content-Type": "application/json", Authorization: "" } }
  );
  return response;
};

const requestPasswordReset = async (email) => {
  const response = await client.post(
    endpoint + "reset_password",
    { email },
    { headers: { "Content-Type": "application/json", Authorization: "" } }
  );
  return response;
};

const resetPassword = async (id, token, newPassword) => {
  const response = await client.post(
    endpoint + `reset_password/${id}/${token}`,
    { newPassword },
    { headers: { "Content-Type": "application/json", Authorization: "" } }
  );
  return response;
};

const refreshToken = async (token) => {
  const response = await client.post(endpoint + "refresh_token/", { token });
  return response;
};

const getAccount = async (id) => {
  const response = await client.get(endpoint + id);
  return response;
};

const editAccount = async (body) => {
  const response = await client.put(endpoint, body);
  return response;
};

const createUser = async (body) => {
  const response = await client.post(`${endpoint}register_user`, body);
  return response;
};

const loginUser = async (body) => {
  const response = await client.post(`${endpoint}login_user`, body);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  signUp,
  login,
  requestPasswordReset,
  resetPassword,
  refreshToken,
  getAccount,
  createUser,
  loginUser,
  editAccount,
};
