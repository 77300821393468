import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

const itemStyle = "text-black border-b border-black p-5";

function TicketList(props) {
  const ticketNumber = props.props.ticketNumber;
  // const notAvailable = tickets.filter((ticket) => ticket.isAvailable === false);
  return (
    <>
      <tr>
        <td className={itemStyle}>{props.props.ticketName}</td>
        <td className={itemStyle}>
          {props.props.limitedQuantity ? ticketNumber : "∞"}
        </td>
        <td className={itemStyle}>{props.props.price}</td>
        <td className={itemStyle}>
          <Link
            to={{
              pathname: "/editticket",
              search: `?${props.props._id}?${props.props.eventId}`,
            }}
            state={props.props}
            className='btn btn-outline'
          >
            <FaRegEdit />
          </Link>
        </td>
      </tr>
    </>
  );
}

export default TicketList;
