import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../index.css';
import { HiMenuAlt4 } from 'react-icons/hi';
import { GrFormClose } from 'react-icons/gr';

const AppNav = ({ white }) => {
  const [navbar, setNavbar] = useState(false);
  const [loggedIn, setLoggedIn] = useState(1);

  useEffect(() => {
    if (window.localStorage.getItem('token') !== null) {
      setLoggedIn(2);
    }
  }, []);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 150) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  const [toggle, setToggle] = useState(false);

  // eslint-disable-next-line
  const toggleNav = () => {
    setToggle(!toggle);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const linkStyle = 'hover:text-primary';

  return (
    <>
      <nav
        className={`navbar  text-black p-4 md:px-16 fixed top-0 left-0 w-full z-[2] justify-between ${
          navbar ? 'bg-white border-b border-gray-100' : ''
        }`}
      >
        {white
          ? (
            <a href='/tickets'>
              {navbar
                ? (
                  <svg
                    width='71'
                    height='15'
                    viewBox='0 0 71 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0 3.60307V11.903L2.0892 13.0366V4.73662L8.39776 1.13355L6.14471 0L0 3.60307Z'
                      fill='#0063BE'
                    />
                    <path
                      d='M3.72513 5.64745V13.8664L5.89626 15V6.74052L9.86984 4.5139V9.21004L7.98546 10.3031V12.6512L12 10.3031V3.21841L9.86984 2.0039L3.72513 5.64745Z'
                      fill='#0063BE'
                    />
                    <path
                      d='M18.5165 3.05406H21.3234C22.5172 3.05406 23.1302 3.82981 23.1302 4.69866C23.1302 5.56751 22.5333 6.32775 21.3234 6.32775H18.5165V3.05406ZM21.3234 8.54642C24.1949 8.51539 25.6467 6.82424 25.6467 4.65212C25.6467 2.55757 24.211 0.757812 21.3234 0.757812C19.5328 0.757812 17.8067 0.757812 16 0.757812V11.6184H18.5165V8.54642H21.3234Z'
                      fill='black'
                    />
                    <path
                      d='M32.8601 4.8383C32.4407 4.15563 31.505 3.78327 30.5049 3.78327C28.1496 3.75224 26.3268 5.24169 26.3268 7.78618C26.3268 10.3151 28.0528 11.8046 30.4403 11.7891C31.2147 11.7736 32.36 11.4633 32.8923 10.6565L33.0214 11.6184H35.1185C35.1185 9.07393 35.1185 6.49842 35.1185 3.95393H32.9407L32.8601 4.8383ZM30.7468 5.75369C32.1342 5.75369 32.8601 6.76218 32.8601 7.86375C32.8601 8.82569 32.0535 9.80315 30.7468 9.80315C29.5854 9.80315 28.6497 9.05842 28.6497 7.78618C28.6497 6.51393 29.5854 5.75369 30.7468 5.75369Z'
                      fill='black'
                    />
                    <path
                      d='M43.2058 4.72969C42.3669 4.03151 41.3829 3.73672 40.1569 3.73672C38.4308 3.73672 36.7208 4.46593 36.7208 6.20363C36.7208 8.00339 38.576 8.59297 40.0601 8.65503C41.1409 8.68606 41.4151 8.98084 41.4151 9.33769C41.4313 9.74109 40.8989 10.0048 40.2214 9.98933C39.3826 9.97381 38.4308 9.80315 37.4468 9.02739L36.4466 10.6099C37.8016 11.696 39.0115 11.9132 40.1891 11.9132C42.7218 11.9132 43.6897 10.5789 43.6736 9.33769C43.6574 7.22763 41.77 6.91733 40.2053 6.87078C39.4309 6.85527 39.0277 6.62254 39.0277 6.20363C39.0277 5.83127 39.4148 5.56751 40.173 5.56751C40.8989 5.56751 41.4635 5.72266 42.0604 6.12606L43.2058 4.72969Z'
                      fill='black'
                    />
                    <path
                      d='M49.5411 9.77212C48.4764 9.77212 47.3956 9.08945 47.3956 7.80169C47.3956 6.34327 48.4925 5.83127 49.5411 5.83127C50.7832 5.83127 51.493 6.70012 51.493 7.80169C51.493 8.94981 50.7348 9.77212 49.5411 9.77212ZM47.3633 15.0007V10.703C48.0408 11.5098 49.0249 11.7891 49.8637 11.7891C52.2028 11.7891 53.8321 10.2841 53.8321 7.80169C53.8321 5.41236 52.2351 3.82981 49.896 3.82981C48.8958 3.82981 47.9924 4.03151 47.2504 4.9469L47.1052 3.96945H45.0242V15.0007H47.3633Z'
                      fill='black'
                    />
                    <path
                      d='M59.1927 11.7891C61.9028 11.7891 63.5644 10.1135 63.5644 7.80169C63.5644 5.48993 61.8544 3.78327 59.1927 3.78327C56.5471 3.78327 54.8372 5.48993 54.8372 7.80169C54.8372 10.1135 56.4987 11.7891 59.1927 11.7891ZM59.1927 9.80315C57.7893 9.80315 57.1924 8.76363 57.1924 7.80169C57.1924 6.83975 57.8054 5.78472 59.1927 5.78472C60.5962 5.78472 61.2092 6.83975 61.2092 7.80169C61.2092 8.81018 60.6123 9.80315 59.1927 9.80315Z'
                      fill='black'
                    />
                    <path
                      d='M65.7787 2.10763V4.00048H64.3591V5.8623H65.7787V8.74812C65.7787 11.1375 67.0854 11.8667 68.8114 11.7891C69.4406 11.7581 69.9084 11.665 70.4891 11.4478L69.9568 9.58593C69.6987 9.71006 69.2631 9.77212 68.9889 9.77212C68.5533 9.77212 68.0855 9.5549 68.0855 8.74812V5.8623H70.1181V4.00048H68.1017V1.85939L65.7787 2.10763Z'
                      fill='black'
                    />
                  </svg>
                  )
                : (
                  <svg
                    width='71'
                    height='15'
                    viewBox='0 0 71 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0 3.60307V11.903L2.0892 13.0366V4.73662L8.39776 1.13355L6.14471 0L0 3.60307Z'
                      fill='white'
                    />
                    <path
                      d='M3.72513 5.64745V13.8664L5.89626 15V6.74052L9.86984 4.5139V9.21004L7.98546 10.3031V12.6512L12 10.3031V3.21841L9.86984 2.0039L3.72513 5.64745Z'
                      fill='white'
                    />
                    <path
                      d='M18.5165 3.05406H21.3234C22.5172 3.05406 23.1302 3.82981 23.1302 4.69866C23.1302 5.56751 22.5333 6.32775 21.3234 6.32775H18.5165V3.05406ZM21.3234 8.54642C24.1949 8.51539 25.6467 6.82424 25.6467 4.65212C25.6467 2.55757 24.211 0.757812 21.3234 0.757812C19.5328 0.757812 17.8067 0.757812 16 0.757812V11.6184H18.5165V8.54642H21.3234Z'
                      fill='white'
                    />
                    <path
                      d='M32.8601 4.8383C32.4407 4.15563 31.505 3.78327 30.5049 3.78327C28.1496 3.75224 26.3268 5.24169 26.3268 7.78618C26.3268 10.3151 28.0529 11.8046 30.4403 11.7891C31.2147 11.7736 32.36 11.4633 32.8923 10.6565L33.0214 11.6184H35.1185C35.1185 9.07393 35.1185 6.49842 35.1185 3.95393H32.9407L32.8601 4.8383ZM30.7468 5.75369C32.1342 5.75369 32.8601 6.76218 32.8601 7.86375C32.8601 8.82569 32.0535 9.80315 30.7468 9.80315C29.5854 9.80315 28.6497 9.05842 28.6497 7.78618C28.6497 6.51393 29.5854 5.75369 30.7468 5.75369Z'
                      fill='white'
                    />
                    <path
                      d='M43.2058 4.72969C42.3669 4.03151 41.3829 3.73672 40.1569 3.73672C38.4308 3.73672 36.7208 4.46593 36.7208 6.20363C36.7208 8.00339 38.576 8.59297 40.0601 8.65503C41.1409 8.68606 41.4151 8.98084 41.4151 9.33769C41.4313 9.74109 40.8989 10.0048 40.2214 9.98933C39.3826 9.97381 38.4308 9.80315 37.4468 9.02739L36.4466 10.6099C37.8016 11.696 39.0115 11.9132 40.1891 11.9132C42.7218 11.9132 43.6897 10.5789 43.6736 9.33769C43.6574 7.22763 41.77 6.91733 40.2053 6.87078C39.4309 6.85527 39.0277 6.62254 39.0277 6.20363C39.0277 5.83127 39.4148 5.56751 40.173 5.56751C40.8989 5.56751 41.4635 5.72266 42.0604 6.12606L43.2058 4.72969Z'
                      fill='white'
                    />
                    <path
                      d='M49.5411 9.77212C48.4764 9.77212 47.3956 9.08945 47.3956 7.80169C47.3956 6.34327 48.4925 5.83127 49.5411 5.83127C50.7832 5.83127 51.493 6.70012 51.493 7.80169C51.493 8.94981 50.7348 9.77212 49.5411 9.77212ZM47.3633 15.0007V10.703C48.0408 11.5098 49.0249 11.7891 49.8637 11.7891C52.2028 11.7891 53.8321 10.2841 53.8321 7.80169C53.8321 5.41236 52.2351 3.82981 49.896 3.82981C48.8958 3.82981 47.9924 4.03151 47.2504 4.9469L47.1052 3.96945H45.0242V15.0007H47.3633Z'
                      fill='white'
                    />
                    <path
                      d='M59.1927 11.7891C61.9028 11.7891 63.5644 10.1135 63.5644 7.80169C63.5644 5.48993 61.8544 3.78327 59.1927 3.78327C56.5471 3.78327 54.8372 5.48993 54.8372 7.80169C54.8372 10.1135 56.4987 11.7891 59.1927 11.7891ZM59.1927 9.80315C57.7893 9.80315 57.1924 8.76363 57.1924 7.80169C57.1924 6.83975 57.8054 5.78472 59.1927 5.78472C60.5962 5.78472 61.2092 6.83975 61.2092 7.80169C61.2092 8.81018 60.6123 9.80315 59.1927 9.80315Z'
                      fill='white'
                    />
                    <path
                      d='M65.7787 2.10763V4.00048H64.3591V5.8623H65.7787V8.74812C65.7787 11.1375 67.0854 11.8667 68.8114 11.7891C69.4406 11.7581 69.9084 11.665 70.4891 11.4478L69.9568 9.58593C69.6987 9.71006 69.2631 9.77212 68.9889 9.77212C68.5533 9.77212 68.0855 9.5549 68.0855 8.74812V5.8623H70.1181V4.00048H68.1017V1.85939L65.7787 2.10763Z'
                      fill='white'
                    />
                  </svg>
                  )}
            </a>
            )
          : (
            <a href='/tickets'>
              <svg
                width='71'
                height='15'
                viewBox='0 0 71 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0 3.60307V11.903L2.0892 13.0366V4.73662L8.39776 1.13355L6.14471 0L0 3.60307Z'
                  fill='#0063BE'
                />
                <path
                  d='M3.72513 5.64745V13.8664L5.89626 15V6.74052L9.86984 4.5139V9.21004L7.98546 10.3031V12.6512L12 10.3031V3.21841L9.86984 2.0039L3.72513 5.64745Z'
                  fill='#0063BE'
                />
                <path
                  d='M18.5165 3.05406H21.3234C22.5172 3.05406 23.1302 3.82981 23.1302 4.69866C23.1302 5.56751 22.5333 6.32775 21.3234 6.32775H18.5165V3.05406ZM21.3234 8.54642C24.1949 8.51539 25.6467 6.82424 25.6467 4.65212C25.6467 2.55757 24.211 0.757812 21.3234 0.757812C19.5328 0.757812 17.8067 0.757812 16 0.757812V11.6184H18.5165V8.54642H21.3234Z'
                  fill='black'
                />
                <path
                  d='M32.8601 4.8383C32.4407 4.15563 31.505 3.78327 30.5049 3.78327C28.1496 3.75224 26.3268 5.24169 26.3268 7.78618C26.3268 10.3151 28.0528 11.8046 30.4403 11.7891C31.2147 11.7736 32.36 11.4633 32.8923 10.6565L33.0214 11.6184H35.1185C35.1185 9.07393 35.1185 6.49842 35.1185 3.95393H32.9407L32.8601 4.8383ZM30.7468 5.75369C32.1342 5.75369 32.8601 6.76218 32.8601 7.86375C32.8601 8.82569 32.0535 9.80315 30.7468 9.80315C29.5854 9.80315 28.6497 9.05842 28.6497 7.78618C28.6497 6.51393 29.5854 5.75369 30.7468 5.75369Z'
                  fill='black'
                />
                <path
                  d='M43.2058 4.72969C42.3669 4.03151 41.3829 3.73672 40.1569 3.73672C38.4308 3.73672 36.7208 4.46593 36.7208 6.20363C36.7208 8.00339 38.576 8.59297 40.0601 8.65503C41.1409 8.68606 41.4151 8.98084 41.4151 9.33769C41.4313 9.74109 40.8989 10.0048 40.2214 9.98933C39.3826 9.97381 38.4308 9.80315 37.4468 9.02739L36.4466 10.6099C37.8016 11.696 39.0115 11.9132 40.1891 11.9132C42.7218 11.9132 43.6897 10.5789 43.6736 9.33769C43.6574 7.22763 41.77 6.91733 40.2053 6.87078C39.4309 6.85527 39.0277 6.62254 39.0277 6.20363C39.0277 5.83127 39.4148 5.56751 40.173 5.56751C40.8989 5.56751 41.4635 5.72266 42.0604 6.12606L43.2058 4.72969Z'
                  fill='black'
                />
                <path
                  d='M49.5411 9.77212C48.4764 9.77212 47.3956 9.08945 47.3956 7.80169C47.3956 6.34327 48.4925 5.83127 49.5411 5.83127C50.7832 5.83127 51.493 6.70012 51.493 7.80169C51.493 8.94981 50.7348 9.77212 49.5411 9.77212ZM47.3633 15.0007V10.703C48.0408 11.5098 49.0249 11.7891 49.8637 11.7891C52.2028 11.7891 53.8321 10.2841 53.8321 7.80169C53.8321 5.41236 52.2351 3.82981 49.896 3.82981C48.8958 3.82981 47.9924 4.03151 47.2504 4.9469L47.1052 3.96945H45.0242V15.0007H47.3633Z'
                  fill='black'
                />
                <path
                  d='M59.1927 11.7891C61.9028 11.7891 63.5644 10.1135 63.5644 7.80169C63.5644 5.48993 61.8544 3.78327 59.1927 3.78327C56.5471 3.78327 54.8372 5.48993 54.8372 7.80169C54.8372 10.1135 56.4987 11.7891 59.1927 11.7891ZM59.1927 9.80315C57.7893 9.80315 57.1924 8.76363 57.1924 7.80169C57.1924 6.83975 57.8054 5.78472 59.1927 5.78472C60.5962 5.78472 61.2092 6.83975 61.2092 7.80169C61.2092 8.81018 60.6123 9.80315 59.1927 9.80315Z'
                  fill='black'
                />
                <path
                  d='M65.7787 2.10763V4.00048H64.3591V5.8623H65.7787V8.74812C65.7787 11.1375 67.0854 11.8667 68.8114 11.7891C69.4406 11.7581 69.9084 11.665 70.4891 11.4478L69.9568 9.58593C69.6987 9.71006 69.2631 9.77212 68.9889 9.77212C68.5533 9.77212 68.0855 9.5549 68.0855 8.74812V5.8623H70.1181V4.00048H68.1017V1.85939L65.7787 2.10763Z'
                  fill='black'
                />
              </svg>
            </a>
            )}

        <ul className='hidden md:flex items-center gap-10'>
          <Link to='/' className={linkStyle}>
            Home
          </Link>
          <Link to='/tickets' className={linkStyle}>
            Ticket
          </Link>

          {loggedIn === 1
            ? (
              <Link to='/userSignIn' className='btn btn-primary items-center'>
                Login
              </Link>
              )
            : (
              <Link to='/appAccount' className='btn btn-primary items-center'>
                Account
              </Link>
              )}
        </ul>

        <button
          onClick={toggleNav}
          id='menu'
          className={`block md:hidden ${
            white ? `text-white ${navbar ? 'text-black' : ''}` : 'text-black'
          } `}
        >
          <HiMenuAlt4 size={24} />
        </button>
      </nav>

      {toggle && (
        <div
          onClick={toggleNav}
          className='w-full h-full pt-8 z-[40] px-4 bg-[#0000007e] fixed top-0 left-0'
        >
          <ul
            onClick={stopPropagation}
            className='menu menu-vertical text-black z-[60] bg-base-100 space-y-6 p-4 rounded-lg border border-black shadow-mobile'
          >
            <div className='mb-6 flex items-center justify-between'>
              <h1 className='font-bold text-xl'>Menu</h1>
              <button onClick={toggleNav}>
                <GrFormClose size={25} />
              </button>
            </div>
            <Link
              to='/'
              className='flex items-center justify-between py-4 border-b border-[#CFCFCF]'
            >
              Home
            </Link>
            <Link
              to='/tickets'
              className='flex items-center justify-between py-4 border-b border-[#CFCFCF]'
            >
              Ticket
            </Link>
            {loggedIn === 1
              ? (
                <Link to='/userSignIn' className='btn btn-primary items-center'>
                  Login
                </Link>
                )
              : (
                <Link to='/appAccount' className='btn btn-primary items-center'>
                  Account
                </Link>
                )}
          </ul>
        </div>
      )}
    </>
  );
};

export default AppNav;
