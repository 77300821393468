export default function EventsPageSkeleton() {
  return (
    <>
      <div className='max-h-screen space-y-6 animate-pulse px-4 pt-24 ease-in-out duration-700 '>
        <div className='fixed top-0 bg-white border-b-gray-300 p-4 left-0 w-full flex items-center justify-between'>
          <div class='bg-slate-200 rounded h-4 w-20'></div>
          <div class='bg-slate-200 rounded h-4 w-10'></div>
        </div>

        <div className='flex items-center justify-between'>
          <div class='bg-slate-200 rounded h-4 w-16'></div>
          <div class='bg-slate-200 rounded h-4 w-24'></div>
        </div>

        <div class='bg-slate-200 rounded-lg h-72 w-full'></div>
      </div>
    </>
  );
}
