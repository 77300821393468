import React, { useEffect, useState } from "react";
import * as yup from "yup";
import eventApi from "../../api/events";
import tickets from "../../api/tickets";
import Loader from "../loader";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

const validationSchema = yup.object().shape({
  ticketName: yup.string().required("Ticket Name field is required"),
  description: yup.string().required("Description field is required"),
  price: yup.number("Please Input a number"),
  ticketNumber: yup.number("Please Input a number"),
});
//
function UpdateTicket({ state: ticketDetails }) {
  const [toggle, setToggle] = useState(2);
  const [limitedQuantity, setLimitedQuantity] = useState(true);
  const [loading, setLoading] = useState(false);
  // 
  const eventId = window.location.href.split("?")[2];
  const eventName = sessionStorage.getItem('currentEventName')
  // 
  const toggleTab = (index) => {
    setToggle(index);
  };

  const handleOnSubmit = async ({
    ticketName,
    description,
    price,
    ticketNumber,
  }) => {
    setLoading(true);
    const response = await tickets.editTicket(
      {
        ticketName,
        description,
        price,
        ticketNumber,
      },
      ticketDetails._id
    );
    // if (!response.ok) return console.log(response), setLoading(false);
    setLoading(false);
    window.location.replace("/editevent?" + eventId + "?" + eventName);
  };

  const handleOnChange = (e) => {
    if (e.target.value === "Limited Quantity") {
      return setLimitedQuantity(true);
    } else if (e.target.value === "Unlimited Quantity") {
      return setLimitedQuantity(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!ticketDetails) {
    return <Loader />;
  }

  return (
    <div className="w-full pt-24">
      <AppForm
        initialValues={{
          ticketName: ticketDetails.ticketName,
          description: ticketDetails.description,
          limitedQuantity: ticketDetails.limitedQuantity,
          price: ticketDetails.price,
          ticketNumber: ticketDetails.ticketNumber,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <div className="flex items-center justify-between gap-4 md:gap-10">
          <div className="text-left w-full">
            <label className="label">
              <span className="label-text">Quantity</span>
            </label>
            <div className="w-full px-3 rounded-lg border border-black flex items-center justify-between">
              <select
                name="tickettype"
                id="ticket"
                className="w-full py-4 border-0 bg-none focus:outline-none"
              >
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
          </div>
        </div>

        <div className="tabcontent active flex flex-col gap-5 md:gap-10">
          <AppFormField name="ticketName" title="Ticket Name" />

          <div className="flex items-center justify-between gap-4 md:gap-10">
            <div className="text-left w-full">
              <label className="label">
                <span className="label-text">Quantity</span>
              </label>
              <div className="w-full px-3 rounded-lg border border-black flex items-center justify-between">
                <select
                  onChange={handleOnChange}
                  name="limitedQuantity"
                  id="cars"
                  className="w-full py-4 border-0 bg-none focus:outline-none"
                >
                  <option value="Limited Quantity">Limited Quantity</option>
                  <option value="Unlimited Quantity">Unlimited Quantity</option>
                </select>
              </div>
            </div>

            {limitedQuantity ? (
              <AppFormField
                marginBot="0"
                margin="0"
                type="number"
                name="ticketNumber"
                title="Number of Tickets"
              />
            ) : null}
          </div>

          {toggle === 2 ? (
            <AppFormField name="price" title="Ticket Price" />
          ) : null}

          <AppFormField name="description" title="Ticket Description" />
        </div>

        <button className="btn btn-primary normal-case" type="submit">
          Save
        </button>
      </AppForm>
    </div>
  );
}

export default UpdateTicket;
