import { useNavigate } from "react-router-dom";
import ContactForm from "./forms/ContactForm";
import { AiOutlineArrowDown } from "react-icons/ai";
export default function Free() {
  const navigate = useNavigate();
  return (
    <>
      <div className='max-w-7xl mx-auto mb-0 md:mb-12 pt-24 md:pt-32 space-y-16 md:space-y-32'>
        <div className='px-7 py-10 text-center space-y-6 md:space-y-12'>
          <h1 className='font-bold uppercase text-4xl md:text-7xl '>
            Get Free Emails
          </h1>
          <p className='text-xl md:text-2xl'>
          Paspot doesn't only push 'p'; we push events. Thanks to the emails we got, we can push your events to our users' emails, so they stumble on your event by mistake.
          </p>
        </div>

        <div className='flex flex-col items-center'>
          <p className='text-center'>Contact Us</p> <AiOutlineArrowDown />
        </div>

        <ContactForm />
      </div>
    </>
  );
}
