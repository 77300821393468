import React, { useState } from "react";
import coordinatorApi from "../../api/coordinator";
import Loader from "../loader";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function Coordinatorform({ eventDetails }) {
  const [eventId, setEventId] = useState();
  const [passcode, setPasscode] = useState();
  const [coordinatorName, setCoordinatorName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const body = {
      eventId: eventId,
      passcode: passcode,
      name: coordinatorName,
    };

    const response = await coordinatorApi.createCoordinator(body);
    // eslint-disable-next-line
    if (!response.ok) return setIsLoading(false), console.log(response.data);

    setIsLoading(false);
    window.location.replace("/coordinator");
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={handleOnSubmit}
      action='submit'
      className='flex justify-evenly flex-col gap-4 text-center'
    >
      <div className='text-left w-full'>
        <label className='label'>
          <span className='label-text'>Coordinator Name</span>
        </label>
        <div className='w-full px-3 rounded-lg hover:border-primary border border-black'>
          <input
            type='text'
            onChange={(e) => setCoordinatorName(e.target.value)}
            name='description'
            className='w-full border-0 bg-transparent py-3 focus:outline-none'
          />
        </div>
      </div>

      <div className='text-left w-full'>
        <label className='label'>
          <span className='label-text'>Event</span>
        </label>
        <div className='w-full px-3 rounded-lg border border-black flex items-center justify-between'>
          <select
            onChange={(e) => setEventId(e.target.value)}
            name='cars'
            id='cars'
            className='bg-transparent py-3 border-0 w-full focus:outline-none'
          >
            <option value='select'>Select</option>
            {eventDetails.map((item) => (
              <option key={item._id} value={item._id}>
                {item.eventName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='text-left w-full'>
        <label className='label'>
          <span className='label-text'>Passcode</span>
        </label>
        <div className='w-full px-3 rounded-lg hover:border-primary border border-black flex items-center justify-between'>
          <input
            onChange={(e) => setPasscode(e.target.value)}
            type={showPassword ? "text" : "password"}
            name='description'
            className='w-full border-0 bg-transparent py-3 focus:outline-none'
          />

          <button
            type='button'
            className='btn btn-sm btn-outline normal-case'
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <AiOutlineEyeInvisible size={20} />
            ) : (
              <AiOutlineEye size={20} />
            )}
          </button>
        </div>
      </div>

      <button className='btn btn-primary normal-case'>Create</button>
    </form>
  );
}

export default Coordinatorform;
