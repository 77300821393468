export default function specialSection({ children, sideThing }) {
  return (
    <div className='flex justify-end pl-4 md:pl-0'>
      <section className='right-0 my-0 max-w-7xl w-full pl-4 md:pl-0'>
        <div className='relative'>
          <div
            style={{
              fontFamily: "var(--font-monaCondensed)",
              position: "absolute",
              transform: "rotate(-90deg) translateX(-100%)",
              transformOrigin: "0px",
              zIndex: -2,
            }}
            className='text-4xl md:text-6xl top-[20%] md:top-[30%] left-0'
          >
            {sideThing}
          </div>

          <div
            className={`text-black flex flex-col gap-6 justify-between md:gap-12 py-4 px-8 md:py-8 md:px-16 bg-secondary h-full w-full`}
          >
            {children}
          </div>
        </div>
      </section>
    </div>
  );
}
