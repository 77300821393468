import {
  RiFacebookBoxLine,
  RiGlobalLine,
  RiInstagramLine,
  RiTwitterXFill,
} from "@remixicon/react";

function socialicons({ link, icon, size }) {
  if (link === "") {
    return null;
  }

  const handleOnClick = () => {
    if (icon === "facebook") {
      window.open("https://www.facebook.com/" + link);
    } else if (icon === "instagram") {
      window.open("https://www.instagram.com/" + link);
    } else if (icon === "twitter") {
      window.open("https://www.twitter.com/" + link);
    } else if (icon === "website") {
      window.open("https://" + link);
    }
  };

  return (
    <button onClick={handleOnClick}>
      {icon === "facebook" ? <RiFacebookBoxLine size={size} /> : null}
      {icon === "instagram" ? <RiInstagramLine size={size} /> : null}
      {icon === "twitter" ? <RiTwitterXFill size={size} /> : null}
      {icon === "website" ? <RiGlobalLine size={size} /> : null}
    </button>
  );
}

export default socialicons;
