import React, { useState } from "react";
import SignUpCustomer from "./forms/SignUpCustomer";

export default function Notice({
  toggleMenu,
  tickets,
  handleOnChange,
  finalPrice,
  buttonLoading,
  handleOnTicketFree,
  componentProps,
  forTicket,
}) {
  const [view, setView] = useState("notice");

  const handleSignUpClick = () => setView("signup");
  const handleLoginClick = () => setView("login");
  const handleBackClick = () => setView("notice");
  return (
    <>
      {view === "notice" && (
        <div className='flex flex-col gap-8'>
          <img
            src='https://ik.imagekit.io/noah/stop%202.png?updatedAt=1715946204054'
            className='w-full h-36 object-contain'
            alt='hand making a stop sign'
          />
          <div className='flex flex-col gap-4'>
            <h1 className='text-xl md:text-2xl font-bold'>
              🚨 HOL AM THERE!! 🚨
            </h1>
            <span className='text-base'>
              Wagwa boss, you need an account to cop a ticket on Paspot cause we
              send your ticket to your Paspot account instead of your email to
              make it easier to find, you grab?
            </span>

            <div
              className={`grid gap-4 ${
                forTicket ? "grid-col-1" : "grid-cols-2"
              }`}
            >
              {forTicket ? (
                ""
              ) : (
                <button
                  onClick={handleSignUpClick}
                  className='btn btn-outline font-medium'
                >
                  Sign up
                </button>
              )}
              <button
                onClick={handleLoginClick}
                className={`btn font-medium ${
                  forTicket ? "btn-primary" : "btn-outline"
                }`}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}

      {(view === "signup" || view === "login") && (
        <SignUpCustomer
          fromBuyTicket={true}
          toggleMenu={toggleMenu}
          setView={setView}
          initialView={view}
          tickets={tickets}
          handleOnChange={handleOnChange}
          finalPrice={finalPrice}
          buttonLoading={buttonLoading}
          handleOnTicketFree={handleOnTicketFree}
          componentProps={componentProps}
        />
      )}
    </>
  );
}
