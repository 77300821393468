import client from "./client";

const endpoint = "events/";

const createEvent = async (
  eventName,
  description,
  image,
  location,
  socialMedia,
  dateAndTime,
  linkName,
  feeSplit
) => {
  const response = client.post(endpoint, {
    eventName,
    description,
    image,
    location,
    socialMedia,
    dateAndTime,
    linkName,
    feeSplit
  });
  return response;
};

const getEvents = async (id) => {
  const response = client.get(endpoint + id);
  return response;
};

const getAllEvents = async () => {
  const response = client.get(endpoint);
  return response;
};

const getEvent = async (id) => {
  const response = await client.get(endpoint + "event/" + id);
  return response;
};

const getEventUser = async (linkName) => {
  const response = await client.get(`${endpoint}eventUser/${linkName}`);
  return response;
};

const updateEvent = async (id, body) => {
  const response = client.post(endpoint + "updateEvent/" + id, body);
  return response;
};

const deleteEvent = async (id) => {
  const response = await client.delete(endpoint + id);
  return response;
};

const uploadImage = async (body) => {
  const response = client.post("/upload", body);
  return response;
};

// -------- EVENTS DASHBOARD FUNCTIONS --------------
const getEventData = async (eventId) => {
  const response = client.get(`${endpoint}dashboard/${eventId}`);
  return response;
};

const getEventTicketSales = async (eventName) => {
  const response = client.get(`${endpoint}ticketsSold/${eventName}`);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createEvent,
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  uploadImage,
  getAllEvents,
  getEventUser,
  getEventData,
  getEventTicketSales,
};
