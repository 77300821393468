import React from "react";
import moment from "moment";

export default function payoutItem(props) {
  const date = moment(props.props.boughtAt, "YYYY-MM-DD, h:mm:ss a").format(
    "ll"
  );
  const email = props.props.owner.split("@")[0]

  return (
    <>
      <div className='flex gap-6 items-center justify-between py-4 border-b border-gray-200'>
        <div className='flex flex-col gap-2'>
          <p className='font-semibold'>{email}</p>
          <div className='badge bg-[#A4D3FF] text-primary'>
            {props.props.ticketClass.ticketName}
          </div>
          {/* <span>₦{props.props.ticketClass.price}</span> */}
        </div>
        <p>{date}</p>
      </div>
    </>
  );
}
