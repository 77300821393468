import client from "./client";

const endpoint = "payouts/";

const requestPayout = async (eventId) => {
  const response = client.post(endpoint, {
    eventId,
  });
  return response;
};

const getPayoutHistory = async (eventId) => {
  const response = client.get(endpoint + "event/" + eventId);
  return response;
};

const pendingPayout = async (eventId) => {
  const response = client.get(endpoint + "event/" + eventId);
  return response;
};

export default {
  requestPayout,
  getPayoutHistory,
  pendingPayout,
};
