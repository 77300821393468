import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppFormField from "./AppFormFied";

function Eventdetail({ data, onChange, onNext }) {
  const [disclosed, setDisclosed] = useState(true);
  const handleOnChange = (e) => {
    if (e.target.value === "Disclosed") {
      return setDisclosed(true);
    } else if (e.target.value === "Undisclosed") {
      return setDisclosed(false);
    }
  };
  return (
    <>
      <AppFormField
        name='eventName'
        title='What’s your event called'
        placeholder='e.g Lasgidi Event'
      />
      <AppFormField
        name='description'
        title='What’s your event about (description)'
        placeholder='Will there be babes👀'
      />
      <div className='flex items-center justify-between gap-4 md:gap-10'>
        <div className='text-left w-full'>
          <label className='label'>
            <span className='label-text'>Location</span>
          </label>
          <div className='w-full p-3 rounded-lg border border-black flex items-center justify-between'>
            <select
              onChange={handleOnChange}
              name='Disclosed'
              id='cars'
              className='w-full border-0 bg-none focus:outline-none'
            >
              <option value='Disclosed'>Disclosed</option>
              <option value='Undisclosed'>Undisclosed</option>
            </select>
          </div>
        </div>

        {disclosed ? (
          <AppFormField
            type='text'
            name='location'
            title='Street Address'
            placeholder='13 Salami Street'
          />
        ) : null}
      </div>
    </>
  );
}

export default Eventdetail;
