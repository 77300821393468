import { create } from 'apisauce';

const urls = {
  localhostURL: 'http://localhost:8080',
  productionURL: 'https://paspot-backend-2c7v.onrender.com'
};

const apiClient = create({
  baseURL: urls.productionURL
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = window.localStorage.getItem('token');
  if (!authToken) return;
  request.headers.Authorization = 'Bearer ' + authToken;
});

export default apiClient;
