import { BiSearch } from "react-icons/bi";
import { MdFilterList } from "react-icons/md";
import SalesItem from "../components/salesItem";
import { Link } from "react-router-dom";
//
export default function Sales({
  ticketsSold,
  salesRevenue,
  tickets,
  feeSplit,
  eventName,
  _id,
}) {
  // console.log("Sales:", tickets);
  return (
    <div className='space-y-8'>
      <h1 className='font-bold text-2xl'>Ticket Sales</h1>
      <div className='bg-[#004dc0] shadow-web border-black border md:h-40 p-4 text-white w-full rounded-md'>
        <div className='space-y-4'>
          <div className='flex items-center justify-between'>
            <span className='text-xs'>Total Revenue</span>
            <span>Ticket sold: {ticketsSold}</span>
          </div>
          <p className='font-bold text-2xl mb-6'>{salesRevenue}</p>
        </div>
      </div>
      <div className='space-y-6'>
        <div className='flex items-center justify-between'>
          <p className='font-bold text-xl'>Fee Split</p>
          <Link
            to={"/eventtest?" + _id + "?" + eventName}
            className='btn btn-outline normal-case btn-sm'
          >
            Edit
          </Link>
        </div>
        <div className='bg-white shadow-web border-black border md:h-40 p-4 w-full rounded-md'>
          <div className='space-y-4'>
            {/* <span>This is how much we are charging you and your attendees</span> */}
            <div className='flex items-center justify-between'>
              <span>You {feeSplit}%</span>
              <span>Attendees {100 - feeSplit}%</span>
            </div>
            <progress
              className='progress progress-primary w-full bg-yellow-300'
              value={feeSplit}
              max='100'
            ></progress>
          </div>
        </div>
      </div>
      <div className='space-y-6'>
        <h1 className='font-bold text-xl'>History</h1>
        <div className='p-4 border-black border rounded-md shadow-web space-y-4'>
          <div className='w-full px-3 rounded-lg border border-black flex items-center gap-2'>
            <BiSearch size={24} />
            <input
              type='text'
              placeholder='Search users name'
              className='w-full border-0 bg-transparent py-3 focus:outline-none'
            />
          </div>

          <ul>
            <li>
              {tickets.map((ticket) => (
                <SalesItem props={ticket} key={ticket._id} />
              ))}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
