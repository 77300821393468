import React from "react";
import Verified from "./Verified";
import Wrong from "./Wrong";

function Popup(isUsed, eventName = null, ticketClass = null, id) {
  return (
    <>
      {isUsed ? (
        <Wrong id={id} />
      ) : (
        <Verified eventName={eventName} ticketClass={ticketClass} id={id} />
      )}
    </>
  );
}

export default Popup;
